import { Formik, FormikHelpers } from "formik";
import React, { useEffect } from "react";
import Link from "../../../components/Link";
import useModal from "../../../helpers/useModal";
import { defaultColors, styled } from "../../../stitches.config";
import {
  bodyLarge,
  bodyLargeStrong,
  h3,
} from "../../commonStyles/Fonts";
import CustomLink from "../../Link";
import { PaymentStatus } from "../../types/Payment";
import * as Yup from "yup";
import { withThemeContext } from "../../components/StitchesThemeProvider";
import checkIcon from "../../../../src/assets/img/white_check.svg";
import { SuccessTipModal } from "./TipModal";

import blikIcon from "../../../../src/assets/img/blik-logo.png";
import blikWaitingIcon from "../../../../src/assets/img/blik_waiting_icon.svg";
import blikWarningIcon from "../../../../src/assets/img/blik_warning_icon.svg";

import { isPaymentFormError, PaymentInvalidData } from "../../../api/payment";

interface Props {
  onPayButtonClick: (amount: number, email: string, blikPay: boolean, message?: string, blikCode?: string) => Promise<void> | void;
  isDarkMode?: boolean;
  descriptionText: string;
  isManyAuthors?: boolean;
}

const defaultAmounts = [
  {
    amountText: "5 zł",
    amountValue: 5,
  },
  {
    amountText: "10 zł",
    amountValue: 10,
  },
  {
    amountText: "20 zł",
    amountValue: 20,
  },
  {
    amountText: "Inna",
  },
];

const FailedTipModal = () => {
  return (
    <MessageTipWrapper>
      <BlikStatus>
        <img src={blikWarningIcon} />
        <p>Transakcja nieudana</p>
        <button>Spróbuj ponownie</button>
      </BlikStatus>
    </MessageTipWrapper>
  );
};

const WaitingTipModal = () => {
  return (
    <MessageTipWrapper>
      {/*<h3 className={h3()}>Oczekiwanie na ukończenie płatności...</h3>*/}
      <BlikStatus>
        <img className="waiting-icon" src={blikWaitingIcon} />
        <p>Potwierdź płatność w aplikacji swojego banku.</p>
      </BlikStatus>
    </MessageTipWrapper>
  );
};

type TipFromValues = {
  email: string;
  message: string;
  amount: number;
  privacyPolicy: boolean;
  blikCode: string;
  blikPay: boolean;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Pole wymagane")
    .email("Wprowadź poprawny adres e-mail"),
  amount: Yup.number().required("Pole wymagane").min(1, "Pole wymagane"),
  message: Yup.string(),
  privacyPolicy: Yup.boolean()
    .required("Musisz zaakceptować warunki")
    .oneOf([true], "Musisz zaakceptować warunki."),
});

const TipFormBasic: React.FC<Props> = ({ onPayButtonClick, isDarkMode, descriptionText, isManyAuthors }) => {
  const { modalData } = useModal();
  const [selectedAmountText, setSelectedAmountText] = React.useState<string>(
    defaultAmounts[1].amountText
  );

  const initialValues: TipFromValues = {
    email: "",
    message: "",
    amount: 10,
    privacyPolicy: false,
    blikCode: "",
    blikPay: false,
  };

  const isOtherValue = selectedAmountText === "Inna";

  switch (modalData?.paymentStatus) {
    case PaymentStatus.SUCCESS:
      if (modalData?.paymentEditorId)
        return (
          <Wrapper>
            <SuccessTipModal authorId={modalData?.paymentEditorId} />
          </Wrapper>
        );
      break;
    case PaymentStatus.WAITING:
      return (
        <Wrapper>
          <WaitingTipModal />
        </Wrapper>
      );
      break;
    case PaymentStatus.FAILED:
      return (
        <Wrapper>
          <FailedTipModal />
        </Wrapper>
      );
      break;
  }

  const onSubmit = ({
    email,
    amount,
    message,
    blikCode,
    blikPay,
    privacyPolicy,
  }: TipFromValues, { setFieldError }: FormikHelpers<TipFromValues>) => {
    onPayButtonClick(amount, email, blikPay, message, blikCode)?.catch(
      (error) => {
        if (isPaymentFormError(error)) {
          const { isFormValid, status, ...fieldErrors } = error as PaymentInvalidData;
          Object.entries(fieldErrors).forEach(([field, error]) => {
            setFieldError(field, error);
          })
        } else { }
      }
    );
  };

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          handleSubmit,
        }) => (
          <>
            <TextColumn>
              {isManyAuthors ?
                <h3 className={h3()}>
                  Podobają Ci się nasze artykuły?
                  <br />
                  Możesz zostawić napiwek.
                </h3>
                :
                <h3 className={h3()}>
                  Podobają Ci się moje artykuły?
                  <br />
                  Możesz zostawić napiwek
                </h3>
              }
              <p className={bodyLarge()} style={{ fontWeight: 600 }}>
                {descriptionText}
              </p>
              <CustomLink
                text="Sprawdź, jak to działa"
                url="/napiwki"
                size="monochromatic"
              />
              <>
                <p className={bodyLargeStrong()} style={{ fontWeight: 600 }}>
                  Kwota napiwku
                </p>
                <AmountWrapper>
                  {defaultAmounts.map(({ amountText, amountValue }, index) => {
                    return (
                      <AmountButton
                        key={index}
                        type="button"
                        selected={selectedAmountText === amountText}
                        onClick={() => {
                          setSelectedAmountText(amountText);
                          amountValue && setFieldValue("amount", amountValue);
                        }}
                      >
                        {amountText}
                      </AmountButton>
                    );
                  })}
                </AmountWrapper>
              </>
              {isOtherValue && (
                <>
                  <p className={bodyLargeStrong()}>Podaj kwotę</p>
                  <Input
                    type="number"
                    name="amount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.amount}
                  />
                </>
              )}
              <Error>{errors.amount && touched.amount && errors.amount}</Error>
            </TextColumn>
            <TipColumn>
              <form /*onSubmit={handleSubmit}*/>
                <p className={bodyLargeStrong()}>
                  {isManyAuthors ? "Wiadomość do autorów (opcjonalnie)" : "Wiadomość do autora (opcjonalnie)"}
                </p>
                <Input
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                />
                <p className={bodyLargeStrong()}>Twój adres e-mail</p>
                <Input
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <Error>{errors.email && touched.email && errors.email}</Error>
                <BlikWrapper>
                  <img src={blikIcon} />
                  <div>
                    <Input
                      pattern="[0-9]*"
                      name="blikCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.blikCode}
                      placeholder={"Kod BLIK"}
                      maxLength={6}
                      autoComplete='off'
                    />
                    {errors.blikCode ?
                      <Error>{errors.blikCode}</Error>
                      :
                      <p>Kod BLIK znajdziesz w aplikacji swojego banku</p>
                    }
                  </div>
                </BlikWrapper>
                <CheckboxWrapper>
                  <Input
                    type="checkbox"
                    className={bodyLarge()}
                    id="privacyPolicy"
                    name="privacyPolicy"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.privacyPolicy}
                  />
                  <span>
                    <label htmlFor="privacyPolicy">Akceptuje</label>&nbsp;
                    <Link to="/info/regulamin">regulamin</Link> i{" "}
                    <Link to="/info/polityka-prywatnosci">
                      politykę prywatności
                    </Link>
                  </span>
                </CheckboxWrapper>
                <Error>
                  {errors.privacyPolicy &&
                    touched.privacyPolicy &&
                    errors.privacyPolicy}
                </Error>
                <PayButtonsWrapper>
                  <PayButtonPrimary
                    /* type="submit" */
                    className={bodyLarge()}
                    disabled={values.blikCode.length === 6 ? false : true}
                    type="button"
                    onClick={() => {
                      setFieldValue("blikPay", true)
                      handleSubmit();
                    }}
                  >
                    Zostaw napiwek BLIKIEM
                  </PayButtonPrimary>
                  <PayButton
                    /*type="submit" */
                    className={bodyLarge()}
                    type="button"
                    onClick={() => {
                      handleSubmit();
                    }}>
                    Zostaw inną metodą
                  </PayButton>
                </PayButtonsWrapper>
              </form>
            </TipColumn>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

export default withThemeContext(TipFormBasic);

const Wrapper = styled("div", {
  paddingTop: "78px",
  paddingBottom: "31px",
  paddingLeft: "12px",
  paddingRight: "12px",
  background: "$grayscale100Fixed",
  "@bp4": {
    display: "flex",
    justifyContent: "space-between",
    gap: "72px",
    alignItems: "flex-start",
    paddingTop: "78px",
    paddingBottom: "31px",
    paddingLeft: "84px",
    paddingRight: "84px",
    width: "948px",
  },
  "@bp5": {
    width: "auto",
  },
  "& .photo-on-left": {
    "& span": {
      color: "$grayscale100",
    }
  }
});

const TextColumn = styled("div", {
  color: "$grayscale0Fixed",
  h3: {
    margin: 0,
  },
  p: {
    fontWeight: "400",
    marginBottom: "32px",
    marginTop: "16px",
    maxWidth: "454px",
  },
});

const TipColumn = styled("div", {
  "p:first-of-type": {
    marginTop: "32px",
  },
  "@bp4": {
    "p:first-of-type": {
      marginTop: 0,
    },
    width: "50%",
  },
  p: {
    color: '$grayscale0Fixed',
  },
  input: {
    color: "$grayscale0Fixed !important",
  }
});

const AmountWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "@bp4": {
    gap: "10px",
  },
});

const AmountButton = styled("button", {
  fontSize: 16,
  whiteSpace: "nowrap",
  lineHeight: "24px",
  letterSpacing: "0px",
  fontWeight: "600",
  padding: "12px 22px",
  border: "2px solid #363535 !important",
  color: "$grayscale0Fixed",
  background: "$grayscale100Fixed",
  variants: {
    selected: {
      true: {
        border: "2px solid #459B2B !important",
        backgroundColor: "rgba(69, 155, 43, 0.1)",
        position: "relative",
        "&:after": {
          content: `url("${checkIcon}")`,
          position: "absolute",
          width: "16px",
          height: "16px",
          top: "-2px",
          right: "-2px",
          background: "#459B2B",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
  },
});

const Textarea = styled("textarea", {
  border: "1px solid rgba(0, 0, 0, 0.05)",
  background: "none",
  fontWeight: 400,
  fontSize: "16px",
  padding: "12px 14px",
  color: "$grayscale0Fixed",
  resize: "none",
  width: "100%",
  height: "100px",
  "&:focus-visible": {
    outline: "1px solid $grayscale10",
  },
  variants: {
    isDarkMode: {
      true: {
        borderColor: "rgba(0, 0, 0, 0.05)",
      },
      false: {
        borderColor: "rgba(255, 255, 255, 0.05)",
      },
    },
  },
});

const Input = styled("input", {
  border: "2px solid #363535 !important",
  background: "none",
  fontWeight: 400,
  fontSize: "16px",
  padding: "12px 14px",
  color: "$grayscale0Fixed !important",
  width: "100%",
  "&:focus-visible": {
    outline: "2px solid $grayscale10",
  },
});

const CheckboxWrapper = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  marginTop: "16px",
  gap: 10,
  input: {
    width: "auto",
    transform: "translateY(30%)",
  },
  span: {
    color: "$grayscale0Fixed",
  }
});

const PayButton = styled("button", {
  background: "$$grayscale100Fixed !important",
  color: "$grayscale0Fixed !important",
  textAlign: "center",
  width: "48%",
  padding: "12px 0",
  marginTop: "16px",
  fontSize: "12px",
  border: "none",
});

const PayButtonPrimary = styled("button", {
  background: "$primary",
  color: "$grayscale100Fixed",
  textAlign: "center",
  border: "none",
  width: "48%",
  padding: "12px 0",
  marginTop: "16px",
  fontSize: "12px",
});

const MessageTipWrapper = styled("div", {
  paddingLeft: "16px",
  paddingRight: "16px",
  textAlign: "center",
  maxWidth: "800px",
});

const Error = styled("span", {
  color: "$aszdziennikPrimary",
  margin: "0 0 10px 0",
  display: "block",
});

const SuccessTipAuthorWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  textAlign: "left",
  h5: {
    margin: 0,
    lineHeight: 1.5,
  },
  p: {
    margin: 0,
  },
});

const SuccessTipSeeAlsoWrapper = styled("div", {
  flexDirection: "column",
  display: "flex",
  span: {
    color: "$grayscale0Fixed",
    textAlign: "left",
  },
  "@bp4": {
    flexDirection: "row",
  },
});

const SuccessTipSeeAlsoHeader = styled("div", {
  display: "flex",
  margin: "40px 0 20px 0",
  alignItems: "center",
  "& p": {
    flexShrink: 0,
    margin: "0 16px 0 0",
    color: "$grayscale0Fixed",
  },
});

const SuccessTipSeeAlsoBlackLine = styled("div", {
  borderTop: "2px solid $grayscale90",
  width: "100%",
});

const PayButtonsWrapper = styled("div", {
  display: "flex",
  justifyContent: "space-between",
});

const BlikWrapper = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  width: "100%",
  marginTop: "40px",
  marginBottom: "40px",

  img: {
    width: "80px !important",
    marginRight: "16px",
    objectFit: "contain !important",
    aspectRatio: "auto !important",
    flexShrink: 0,
  },

  div: {
    width: "100%",
  },

  input: {
    textAlign: "center",
  },

  p: {
    color: "#5A5A5A",
    fontSize: "14px",
    maxWidth: "200px",
    margin: "6px 0 0 0 !important",
  }
});

const BlikStatus = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  "& .waiting-icon": {
    marginLeft: "48px",
  },

  p: {
    textAlign: "center",
    color: "$grayscale0Fixed",
    maxWidth: "240px",
    fontWeight: 700,
  },

  img: {
    marginBottom: "40px",
    width: "140px",
  },

  button: {
    background: "$primary",
    color: "$grayscale0Fixed",
    textAlign: "center",
    border: "none",
    width: "48%",
    padding: "12px 0",
    marginTop: "16px",
    fontSize: "14px",
    fontWeight: 700,
  }
})
