import React, { Suspense } from "react";
import TrackingCodeParser from "../../helpers/TrackingCodeParser"
import Breadcrumbs from "../../redesign/components/Breadcrumbs";
import { styled } from "../../stitches.config";
import SlateRenderer from "./Parts/Slate/SlateRenderer";
import Parts from "./Parts/Parts";
import { withThemeContext } from "../../redesign/components/StitchesThemeProvider";

import AtavistBottomAuthors from "../../redesign/components/AtavistBottomAuthors";
const DotsHeader = React.lazy(() => import(/* webpackChunkName: "redesign__atoms__DotsHeader" */ '../../redesign/atoms/DotsHeader'));

class Header extends React.Component {
  componentDidMount() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.setAttribute("src", "/vendor/Hyphenator.js");

    document.getElementsByTagName("body")[0].appendChild(s);
  }


  render() {

    const { article, isDarkMode } = this.props;
    const isBreadcrumbsInverted = !article.semi;
    const parts = (article && article?.TitlePart ? article.TitlePart : []);
    const isSemi = article?.semi;
    const isDesktop = window.innerWidth >= 996;
    const isRichArticle = article?.display_type === 'RICH_ARTICLE';
    const isLive = article?.display_type === "LIVE";
    const isBrid = article?.display_type === "BRID";

    return (
      <Section isDarkMode={isDarkMode} isBrid={isBrid} isRichArticle={isRichArticle} isLive={isLive} isSemi={isSemi} className={!isRichArticle ? "" : "row"}>
        <div class={!isRichArticle && !isBrid && "container"}>
          <SectionWrapper isDarkMode={isDarkMode} className={!isRichArticle ? "article-column-content" : "col-lg-8 article-column-content"}>
            <TrackingCodeParser trackingCode={article?.BrandingAnalyticsCode} />

            {!isRichArticle && !isBrid && <Breadcrumbs
              category={{
                name: article?.category?.name,
                link: article?.category?.url,
              }}
              invert={isBreadcrumbsInverted}
              allWhite={isSemi}
            />}
            <div className={isSemi ? " article-type-semi" : ""}>
              {["ARTICLE_SLATE", "ARTICLE_VIDEO", "ARTICLE_PODCAST", "LIVE"].includes(article?.display_type) ? (
                <SlateRenderer
                  article={article}
                  parts={article?.TitlePart}
                  hideAdslots={this.props.hideAdslots}
                />
              ) : (
                <Parts titlePart parts={parts} article={article} hideAdslots={this.props.hideAdslots} />
              )}
              {isRichArticle && <AtavistBottomAuthors article={article} />}
            </div>
          </SectionWrapper>
        </div>
        {!isRichArticle && !isBrid &&
          <Suspense fallback={null}>
            <DotsHeader />
          </Suspense>}
      </Section>
    );
  }
}

export const Section = styled("section", {
  backgroundColor: "#fff",
  paddingTop: "24px",
  position: "relative",
  "@bp4": {
    paddingTop: "32px",
    paddingBottom: "80px",
  },
  "@bp5": {
    "& .article-column-content": {
      width: "840px"
    }
  },
  ".container": {
    display: "flex",
    justifyContent: "center",
    "@bp5": {
      display: "block"
    }
  },
  // ".dots": {
  //   position: "absolute",
  //   right: "0",
  //   bottom: "0",
  //   height: "350px",
  //   width: "100px",
  //   display: "none",
  //   "@bp5": {
  //     /* nie 996px ponieważ zasłania treść przy tapecie */
  //     display: "block",
  //   },
  //   "svg": {
  //     fill: "$primary",
  //   },
  // },
  variants: {
    "isSemi": {
      true: {
        backgroundColor: "#131212",
        paddingBottom: "80px",
        "@bp4": {
          ".article-column-content": {
            margin: "0 auto"
          }
        },
        ".dots": {
          "svg": {
            fill: "#2b2a2a"
          }
        },
        ".inn-background-logo": {
          "svg": {
            stroke: "#2b2a2a",
          }
        }
      },
      false: {}
    },
    "isRichArticle": {
      true: {
        backgroundColor: "#F5F5F5",
        paddingTop: 0,
        "@bp4": {
          paddingTop: 0,
          paddingBottom: 0
        },
        "@bp5": {
          ".article-column-content": {
            width: "100%"
          }
        }
      },
      false: {}
    },
    "isBrid": {
      true: {
        backgroundColor: "#E5E5E5",
        "@bp4": {
          paddingBottom: 0
        },
      },
      false: {}
    },
    "isDarkMode": {
      true: {
        backgroundColor: "#131212",
        ".dots": {
          "svg": {
            fill: "#2b2a2a"
          }
        }
      },
      false: {}
    },
    "isLive": {
      "@bp4": {
        paddingBottom: "56px"
      }
    }
  }
});

export const SectionWrapper = styled("div", {
  "h1": {
    color: "$grayscale100"
  },
  ".article-type-semi": {
    color: "white !important",
    "div, p, a, h1": {
      color: "white !important"
    },
    ".author-date": {
      minHeight: '46px',
      ".social-btn-icon": {
        backgroundColor: "$primary"
      }
    },
    ".article-rating": {
      borderColor: "white !important"
    },
    ".vehicle-card": {
      "div, p, a, h1": {
        color: "$grayscale100Fixed !important"
      },
    }
  },
  variants: {
    "isDarkMode": {
      true: {
        "p": {
          color: "#E7E7E7"
        },
        "span": {
          color: "#8D8D8D"
        }
      },
      false: {
        "p": {
          color: "#131212"
        }
      }
    }
  }
});

export default withThemeContext(Header);