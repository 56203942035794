import React from 'react';
import { styled } from "../../../../../../stitches.config"
import { body } from "../../../../../../redesign/commonStyles/Fonts";
import arrowDown from "../../../../../../redesign/assets/icons/arrow-down-white.svg"


const ExpandBarBizon = () => {
    return (
        <StyledExpandBarBizon>
            <TextWrap>
                <span className={body()}>
                    Rozwiń
                    <Arrow />
                </span>
            </TextWrap>
        </StyledExpandBarBizon>
    );
};

const StyledExpandBarBizon = styled('div', {
    width: "100%",
    borderBottom: "3px solid $primary",
    background: "transparent",
    position: "relative",
    bottom: "30px",
})

const Arrow = styled('div', {
    width: "10px",
    height: "6px",
    backgroundImage: `url("${arrowDown}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    marginLeft: "6px",
    marginTop: "8px",
})

const TextWrap = styled('div', {
    display: "flex",
    "& span": {
        display: "flex",
        color: '$grayscale0Fixed',
        fontFamily: "$grotesk",
        background: "$primary",
        padding: "6px 14px 8px 12px",
        fontWeight: "600 !important",
        fontSize: "14px !important",
        lineHeight: "18px",
    },
})

export default ExpandBarBizon