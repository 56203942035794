import React, { Component } from "react";
import Lazyload from "react-lazyload";
import { styled } from "../../../../stitches.config";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import VizSensor from 'react-visibility-sensor';
import throttle from 'lodash.throttle'

import OnnetworkPlayer from "../../../OnnetworkPlayer";

import { pushDataLayer } from "../../../../helpers";
import { withNoSSR } from "../../../../helpers/NoSSR";

@observer
class OnnetworkVideo extends Component {
  ref = React.createRef()
  scroll = this.handleScroll.bind(this);
  throttledHandleScroll = throttle(this.scroll, 300)
  @observable
  closed = false;

  @observable
  fixed = true;

  @observable
  offset = 0;

  @observable
  deepView = false;

  constructor(props) {
    super(props);
    this.deepViewObserver = new IntersectionObserver(([entry]) => {

      if (!entry)
        return;

      if (entry.boundingClientRect.top < 0 || entry.isIntersecting) {
        if (!this.deepView) {
          this.deepView = true;
          // console.log('[ES] RegisterDeepView');

          // window._gaq.push(["_trackEvent",
          //   "ANALYTICS",
          //   "OnnetworkPlayer_1_InViewport"
          // ]);

          // pushDataLayer({
          //   event: "analyticsEvent",
          //   eventCategory: "User_article_read",
          //   eventLabel: '312,wedadas-awe NUMBER_OF_INTERACTIONS', // 'EXIT' = exit screen
          //   eventAction: 'wysokość_scrolla', // 0, 300
          //   eventValue: undefined
          // })
        }
      }
    }, {
      root: null,
      threshold: 0
    });
  }

  handleScroll() {
    this.offset = this.ref.current?.getBoundingClientRect().top
  }

  componentDidMount() {
    window.addEventListener("scroll", this.throttledHandleScroll);
    if (this.props.hookPlayer === false) {
      this.closed = true;
    }
    if (this.ref.current)
      this.deepViewObserver.observe(this.ref.current);
  }

  onChangeVisibility(isVisible) {
    this.fixed = !isVisible;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledHandleScroll);
    this.deepViewObserver.disconnect();
  }

  render() {
    return (
      <div ref={this.ref}>
        <Lazyload height={200} offset={200} once={true}>
          <VizSensor onChange={(isVisible) => this.onChangeVisibility(isVisible)} partialVisibility={true} active={this.offset < 0} delayedCall={true}>
            <Container>
              <FloatingContainer className={(this.fixed && !this.closed && this.offset < 0) ? "onnetwork-video--fixed" : null}>
                <button className="close" onClick={(e) => (this.closed = true)}>&times;</button>
                <OnnetworkPlayer mid={this.props.mid} />
              </FloatingContainer>
            </Container>
          </VizSensor>
        </Lazyload>
      </div>
    );
  }
}

const Container = styled("div", {
  width: "100%",
  height: "auto"
  /* position: relative; */
  /* margin-top: 23px; */
  /* padding-bottom: 56%; */
});

const FloatingContainer = styled("div", {
  margin: "0",
  position: "relative",
  ".close": {
    display: "none",
    background: "rgb(255, 255, 255, 0.8)",
    border: "none",
    color: "red",
    fontSize: "30px",
    fontWeight: "30px",
    position: "absolute",
    right: "0",
    bottom: "-30px",
    "@bp4": {
      top: "-10px",
      bottom: "auto"
    }
  },
  "&.onnetwork-video--fixed": {
    position: "fixed",
    left: "-1px",
    right: "-1px",
    top: "50px",
    zIndex: 9999999,
    ".close": {
      display: "inline-block" /** zawsze na wierzchu, później reklama i reszta */
    },
    "@bp4": {
      width: "360px",
      right: "20px",
      bottom: "20px",
      left: "auto",
      top: "auto"
    }
  }
});

const Placeholder = styled("div", {
  width: '100%',
  aspectRatio: '16 / 9'
});

export default withNoSSR(OnnetworkVideo, <Placeholder />);
