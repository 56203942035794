import React, { useCallback } from 'react';
import { styled } from "../../../stitches.config"
import { pStrong } from "../../../redesign/commonStyles/Fonts";
import fullStarIcon from "../../../redesign/assets/icons/star-full.svg";
import emptyStarIcon from "../../../redesign/assets/icons/star-empty.svg";
import halfStarIcon from "../../../redesign/assets/icons/star-half.svg";
import { withThemeContext } from "../../../redesign/components/StitchesThemeProvider";
type RatingProps = {
    rating: number,
    isDarkMode: boolean,
}

const Rating: React.FC<RatingProps> = ({
    rating,
    isDarkMode,
}) => {
    const numberOfFullStars = Math.floor(rating);
    const numberOfEmptyStars = Math.floor(5 - rating);
    const numberOfHalfStars = 5 - numberOfFullStars - numberOfEmptyStars;
    const border = (isDarkMode) ? "white" : "black";

    const renderStars = useCallback(() => {
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= numberOfFullStars) {
                stars.push(<StyledFullStar key={`ratingStar${i}`} />);
            } else if (i <= (numberOfFullStars + numberOfHalfStars)) {
                stars.push(<StyledHalfStar key={`ratingStar${i}`} />);
            } else {
                stars.push(<StyledEmptyStar key={`ratingStar${i}`} />);
            }
        }
        return stars;
    }, [rating]);

    return (
        <>
            <StyledRating className="article-rating" border={border}>
                <StyledP className={pStrong()} >Ocena redakcji<span>:</span></StyledP>
                <StyledStars>
                    {renderStars()}
                </StyledStars>
                <StyledRateValue className={pStrong()} >{rating}/5</StyledRateValue>
            </StyledRating>
        </>
    );
};

const StyledRating = styled('div', {
    borderTop: "1px solid #131212",
    borderBottom: "1px solid #131212",
    display: "flex",
    margin: "20px 0",
    "@bp4": {
        margin: "-45px 0 20px 0",
    },
    variants: {
        border: {
            white: {
                borderColor: "#ffffff",
            },
            black: {
                borderColor: "#131212",
            }
        }
    }
})
const StyledRatingText = styled('p', {
    margin: "10px 0",
    fontWeight: "700",
})
const StyledP = styled(StyledRatingText, {
    fontSize: "14px",
    lineHeight: "16px",
    marginRight: "10px",
    "@bp4": {
        marginRight: "10px",
        fontSize: "18px",
        lineHeight: "22px",
    },
    "span": {
        color: "var(--colors-primary)"
    }

})
const StyledRateValue = styled(StyledRatingText, {
    fontSize: "18px",
    lineHeight: "15px",
    marginLeft: "10px",
    "@bp4": {
        marginLeft: "10px",
        fontSize: "21px",
        lineHeight: "21px",
    }
})
const StyledStars = styled('div', {
    display: "flex",
    alignItems: "center"
})
const StyledStar = styled('div', {
    width: "16px",
    height: "16px",
    margin: "0 5px 4px 5px",
    backgroundSize: "cover",
    "@bp4": {
        width: "22px",
        height: "22px",
    }
})
const StyledFullStar = styled(StyledStar, {
    backgroundImage: `url("${fullStarIcon}")`,
})
const StyledHalfStar = styled(StyledStar, {
    backgroundImage: `url("${halfStarIcon}")`,
})
const StyledEmptyStar = styled(StyledStar, {
    backgroundImage: `url("${emptyStarIcon}")`,
})
export default withThemeContext(Rating)