import React, { useEffect, useRef } from "react";
import Link from "../../components/Link";
import Media from "../../components/common/Media";
import { defaultColors, styled } from "../../stitches.config";
import { bodyLarge, body } from "../commonStyles/Fonts";
import MicrophoneIcon from "../assets/icons/microphone-icon.svg";
import PlayerIconBackground from "../assets/icons/player-icon.svg";
import PlayerIconActive from "../assets/icons/player-icon-active.svg";
import HotNews from "../assets/icons/hot-news.svg";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// embedy
import UniversalEmbedBox from "./UniversalEmbedBox";
interface PhotoOnLeftProps {
  boxSize?: "xxl" | "xs" | "xxs";
  article?: any;
  style?: string;
  isDarkMode?: boolean;
  isLastBox?: boolean;
  dataAnalyticsName?: string;
}

const ResizableContainer = ({ content }: { content: any }) => {
  const container = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (container && container.current) {
      const parent = container.current?.parentElement;
      const height = container.current?.offsetHeight;
      const parentHeight = (parent ? parent.offsetHeight : 100000);
      // console.log('ResizableContainer', height, parentHeight, parent);

      if (height > parentHeight) {
        container.current?.classList.add('too-much-text');
      }
    }
  }, []);


  return <ResizableContainerWrapper ref={container}>{content}</ResizableContainerWrapper>;
}

const ResizableContainerWrapper = styled("span", {
  '@mobile': {
    letterSpacing: '-0.6px',

    '&.too-much-text': {
      letterSpacing: '-0.8px',
      hyphens: 'auto'
    }
  }
});


const PhotoOnLeft: React.FC<PhotoOnLeftProps> = ({
  boxSize = "xxs",
  isDarkMode = false,
  article,
  style,
  dataAnalyticsName
}) => {

  const partner = (article.partner ? <Partner>{article.partner}</Partner> : null);
  const showSkeleton = article.url ? false : true;

  const hasEmbed = article.embed && article.embed.type;

  return (
    <>
      {hasEmbed && article.embed.type != "iframe" ?
        <UniversalEmbedBox article={article} style={style} />
        :
        <StyledPhotoOnLeft
          className={`photo-on-left style--${(style ? style : "")}`}
          boxSize={boxSize}
          data-is-dark-mode={isDarkMode}
        >
          <Link to={article.url} data-analytics-name={dataAnalyticsName}>
            <ImageWrapper>
              {article.podcast && <PodcastIcon boxSize={boxSize} />}
              {article.player && <PlayerIcon boxSize={boxSize} />}
              {article?.iconHotNews && <IconHotNews />}
              {boxSize === "xxl" && (
                <Media
                  // media={media}
                  src={article?.picture?.img}
                  preserveRatio={false}
                  aspectRatio={1.5}
                  width={300}
                />
              )}
              {boxSize === "xs" && (
                <Media
                  // media={media}
                  src={article?.picture?.img}
                  preserveRatio={false}
                  aspectRatio={1.5}
                  width={108}
                />
              )}
              {boxSize === "xxs" && (
                <Media
                  // media={media}
                  src={article?.picture?.img}
                  preserveRatio={false}
                  aspectRatio={1.5}
                  width={86}
                />
              )}
              {partner}
            </ImageWrapper>

            <StyledWrap className={boxSize === "xxs" ? body() : bodyLarge()} mode={isDarkMode ? "dark" : "default"}>
              {showSkeleton ?
                <Skeleton count={3} />
                :
                <ResizableContainer content={article.pile_title || article.title} />

              }
            </StyledWrap>

          </Link>
        </StyledPhotoOnLeft>
      }
    </>
  );
};
const Partner = styled("span", {
  display: 'block',
  position: 'absolute',
  left: '0',
  top: '0',
  padding: '0px 8px',
  backgroundColor: '#D8D8D8',
  color: '#8B8B8B',
  lineHeight: '16px',
  fontSize: '11px',
  zIndex: '4',
});

const ImageWrapper = styled("div", {
  float: "left",
  flexShrink: 0,
  position: "relative",
  "& img": {
    width: "100%",
    objectFit: "cover",
  },
});
const StyledWrap = styled("span", {
  width: "100%",
  textDecoration: "none !important",
  marginLeft: "16px",
  paddingLeft: "2px",
  overflow: "hidden",
  variants: {
    mode: {
      default: {
        color: "$grayscale100",
      },
      dark: {
        color: "$grayscale0",
      }
    },
  },
});
const StyledPhotoOnLeft = styled("div", {
  marginBottom: "24px",
  "@desktop": {
    marginBottom: "32px",
  },
  "&[data-is-dark-mode=true]": {
    color: defaultColors.grayscale0,
    "& a": {
      color: defaultColors.grayscale0,
      "&:hover": {
        textDecorationColor: defaultColors.grayscale0,
      },
    },
    [`& ${StyledWrap}`]: {
      color: defaultColors.grayscale0,
    }
  },

  "& a": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: "$grayscale100",
    },
  },
  variants: {
    boxSize: {
      xxl: {
        [`& ${ImageWrapper}`]: {
          width: "300px",
          height: "200px",
        },
        [`& ${StyledWrap}`]: {
          maxHeight: "200px",
        },
      },
      xs: {
        [`& ${ImageWrapper}`]: {
          width: "108px",
          height: "72px",
        },
        [`& ${StyledWrap}`]: {
          maxHeight: "72px",
        },
      },
      xxs: {
        [`& ${ImageWrapper}`]: {
          height: "56px",
          width: "85px",
        },
        [`& ${StyledWrap}`]: {
          maxHeight: "56px",
        },
      },
    },
  },
  // kolory 
  "&.style--red": {
    [`${StyledWrap} > span`]: {
      backgroundColor: "#EA3A3E",
      color: "white",
      padding: "0 2px",
      boxShadow: "2px 0 0 red, -2px 0 0 #EA3A3E",
    },

    "& a": {
      "&:hover": {
        textDecoration: "underline",
        textDecorationColor: "$grayscale0Fixed",
      },
    },
  },

  "&.style--primary": {
    [`${StyledWrap} > span`]: {
      backgroundColor: "$primary",
      color: "white",
      padding: "0 2px",
      boxShadow: "2px 0 0 red, -2px 0 0 $primary",
    },
  },

  "&.style--black": {
    [`${StyledWrap} > span`]: {
      backgroundColor: "#131212",
      color: "white",
      padding: "0 2px",
      boxShadow: "2px 0 0 #131212, -2px 0 0 #131212",
    },
  },
  "&.style--with-padding": {
    "& span": {
      color: "$grayscale0Fixed",
    }
  }
});
const PodcastIcon = styled("div", {
  background: "$primary",
  position: "absolute",
  left: 0,
  zIndex: 100,
  backgroundImage: `url("${MicrophoneIcon}")`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  width: "20px",
  height: "20px",
  backgroundSize: "12px",
  variants: {
    boxSize: {
      xxl: {
        top: "180px",
      },
      xs: {
        top: "52px",
      },
      xxs: {
        top: "36px",
      },
    },
  },
});
const PlayerIcon = styled("div", {
  position: "absolute",
  zIndex: 100,
  backgroundImage: `url("${PlayerIconBackground}")`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",

  "&:hover": {
    backgroundImage: `url("${PlayerIconActive}")`,
  },

  variants: {
    boxSize: {
      xxl: {
        top: "76px",
        left: "126px",
        width: "48px",
        height: "48px",
        backgroundSize: "48px",
      },
      xs: {
        top: "20px",
        left: "38px",
        width: "32px",
        height: "32px",
        backgroundSize: "32px",
      },
      xxs: {
        top: "14px",
        left: "29px",
        width: "28px",
        height: "28px",
        backgroundSize: "28px",
      },
    },
  },
});

const IconHotNews = styled("div", {
  position: 'absolute',
  right: '0px',
  bottom: '0px',
  width: "26px",
  height: "26px",
  backgroundImage: `url("${HotNews}")`,
  backgroundSize: "14px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundColor: "$aszdziennikPrimary",
  zIndex: '4',
});


export default PhotoOnLeft;
