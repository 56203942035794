import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { styled, Global } from "../stitches.config";
import LazyLoad from "react-lazyload";

import Header from "./Article/Header";
import { withThemeContext } from "../redesign/components/StitchesThemeProvider";

import { IS_FEATURE_enableComments } from "./common/constants/constants";

const Comments = React.lazy(() => import(/* webpackChunkName: "react-facebook" */"react-facebook").then(m => ({ default: m.Comments })));

@inject("ArticleStore", "CategoryStore", "UIStore")
@observer
class ArticleRich extends Component {

  render() {
    const { article } = this.props;
    /*4061, 4056*/
    const hasSpecialBackgroundColor = ["379831"].includes(article.OID)
    const hasSpecialContent = ["421528"].includes(article.OID)

    this.props.setForceDarkMode(false);

    return (
      <ArticleWrapper
        className={hasSpecialBackgroundColor ? "special-rich-article-background nosticky" : hasSpecialContent ? "special-rich-article-content nosticky" : "nosticky"}
        style={hasSpecialBackgroundColor ? { backgroundColor: "#e62e2e" } : {}}>
        <Global styles={{
          ".Activated_content__2QBG5 img": { maxWidth: "100%" }
        }} />
        <Header article={article} />
        {IS_FEATURE_enableComments &&
          <LazyLoad once={true} offest={500} height={200}>
            <CommentsWrapper>
              <Comments width="100%" numPosts={2} href={article.fb_url} />
            </CommentsWrapper>
          </LazyLoad>
        }
      </ArticleWrapper>
    );
  }
};

const CommentsWrapper = styled("div", {
  margin: "0 auto",
  maxWidth: "660px"
});

const ArticleWrapper = styled("div", {
  /* artykuł konkursowy - rich artylce #4056, #4061 */
  "&.special-rich-article-background": {
    section: {
      background: "#e62e2e !important",
      a: {
        color: "#f4e8d4 !important",
        "&:hover": {
          color: "#f4e8d4 !important"
        }
      }
    },
    ".rich-article-background-img": {
      background: "none !important"
    }
  },
  "&.special-rich-article-content": {
    ".rich-article-background-img": {
      background: "none !important"
    },
    ".article-info__picsInfo": {
      "&::before": {
        content: '"Ilustracje: " !important'
      }
    }
  }
});

export default withThemeContext(ArticleRich)