import React, { useState, useEffect, useRef, useCallback } from 'react'
import { styled, css } from "../../stitches.config";
import { observer } from "mobx-react"

import { useStores } from '../../helpers/useStores'
import { pushDataLayer } from '../../helpers/pushDataLayer'

import { IMG_SRC_PARAMS_REGEX } from './constants/constants'

import NoSSR from '../../helpers/NoSSR';

//Powoduje błędy kompilacji edytora, ale raczej nie wymagane - https://gitlab.com/skolimow/natemat/natemat/-/merge_requests/1781
//import { useThemeContext, withThemeContext } from '../../redesign/components/StitchesThemeProvider';

/*komponent otypowanny w ProgressiveMedia.tsx, ale w BO nie ma typescripta */
const ProgressiveMedia = observer(({
    src = '',
    embed,
    preserveRatio = true,
    isMobileOptionActive = false,
    aspectRatio = 1.78,
    aspectRatioMobileOption = "",
    width = 'auto',
    height = 'auto',
    alt = "",
    title = "",
    centerX,
    mediaProperties = {},
    fetchpriority = undefined,
    disablePreload = false,
    onLoadFinished = () => { },
}) => {
    const { APP_SETTINGS, MEDIA_DOMAIN, devicePixelRatio } = window;
    const isPlaceholder = !!APP_SETTINGS?.placeholder && !APP_SETTINGS.placeholder_disabled_pathes?.includes(window.location.pathname);

    const [isFullsizeLoaded, setIsFullsizeLoaded] = useState(false);
    const [containerDimensions, setContainerDimensions] = useState({ width: null, height });
    const [isWidthCalculated, setIsWidthCalculated] = useState(false);
    const [visibilityTimeout, setVisibilityTimeout] = useState(!isPlaceholder);
    const { UIStore } = useStores();
    const ref = useRef(null);
    const timeout = useRef(null);

    const handleFullsizeLoad = () => {
        setIsFullsizeLoaded(true)
        onLoadFinished()
    };

    const getMediaUrl = useCallback((src, mediaProperties, width) => {
        const { MEDIA_DOMAIN } = window;

        const mediaSrc = src ?? "/";
        const hasMediaProperties = Object.keys(mediaProperties).length !== 0 && mediaProperties.constructor === Object;

        const legacyFullsize = mediaSrc.replace(IMG_SRC_PARAMS_REGEX, `,${Math.ceil(width)},${preserveRatio ? 0 : Math.ceil(width / (isMobileOptionActive && aspectRatioMobileOption ? aspectRatioMobileOption : aspectRatio))},${preserveRatio ? 0 : 1},0`);
        const sourceUrl = hasMediaProperties
            ? `${MEDIA_DOMAIN}/${mediaProperties.hash},${Math.ceil(width)},${preserveRatio ? 0 : Math.ceil(width / (isMobileOptionActive && aspectRatioMobileOption ? aspectRatioMobileOption : aspectRatio))},${preserveRatio || ["gif", "mp4"].includes(mediaProperties.media?.format) ? 0 : 1},0.${mediaProperties.media?.format}`
            : legacyFullsize

        return sourceUrl;
    }, [preserveRatio, isMobileOptionActive, aspectRatio, aspectRatioMobileOption]);

    const appRoot = document.getElementById('root');

    const setSize = useCallback((entry) => {
        // console.log('[ES] ProgressiveMedia setSize', sourceUrl, ref.current);
        if (ref.current !== null) {
            let refWidth = ref.current.offsetWidth;

            // Handle screening debounce
            if (entry && entry.target == appRoot && refWidth > appRoot.offsetWidth) {
                refWidth = appRoot.offsetWidth;
            }

            // console.log('[ES] ProgressiveMedia setSize-in refWidth, preserveRatio, width, height', refWidth, preserveRatio, height, width);

            if (preserveRatio && ![height, width].includes("auto")) {
                const containerHeight = height / (width) * refWidth;
                setContainerDimensions({ width: refWidth, height: containerHeight });
            } else if (!preserveRatio && ![height, width].includes("auto")) {
                setContainerDimensions({ width, height })
            } else if (refWidth > 0) {
                setContainerDimensions({ width: refWidth, height: "auto" });
            }
        }
    }, []);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(e => {
            e.forEach(entry => {
                setSize(entry);
            });
        });
        if (ref.current !== null) {
            resizeObserver.observe(ref.current);

            //Observe screening debounce
            if (appRoot)
                resizeObserver.observe(appRoot);
        }

        // zmieniony czas z 1500 na 2500 dla akcji #5823
        // zmienionu czas z 2500 na 3000 dla akcji #8294
        if (isPlaceholder) {
            timeout.current = setTimeout(() => {
                setVisibilityTimeout(true);
            }, 3000)
            pushDataLayer({
                'event': 'analyticsEvent',
                'eventCategory': 'Preload',
                eventAction: 'Preload loaded',
                eventLabel: window.location.pathname,
                eventValue: undefined
            })
        }

        return () => {
            if (timeout.current !== undefined) {
                clearTimeout(timeout.current)
            }
            //resizeObserver.disconnect();
        }
    }, []);

    useEffect(() => {
        if (timeout.current && visibilityTimeout)
            setSize();
    }, [visibilityTimeout]);

    useEffect(() => {
        if (containerDimensions.width > 0) { //może być null
            setIsWidthCalculated(true);
        }
    }, [containerDimensions]);

    //Powoduje błędy kompilacji edytora, ale raczej nie wymagane - https://gitlab.com/skolimow/natemat/natemat/-/merge_requests/1781
    //const { isDarkMode } = useThemeContext();

    const imageWidth = containerDimensions.width * devicePixelRatio;
    const sourceUrl = getMediaUrl(src, mediaProperties, imageWidth);
    //  sprawdzanie rozszerzenia pliku
    let urlExtension = sourceUrl.split(".").pop();
    const sourceUrlFallback = sourceUrl.replace("webp", "jpg")

    // console.log('width', imageWidth);
    // console.log('mediaProperties', mediaProperties);
    // console.log('sourceUrlFallback', sourceUrlFallback);


    let WrapperSize = {
        minWidth: containerDimensions.width,
        minHeight: (preserveRatio ? containerDimensions.height : height)
    }

    if (!!window.SSR) {
        const proportion = aspectRatio || height / width;

        WrapperSize = {
            width: '100%',
            aspectRatio: proportion
        };
    }

    let Media = null;
    let fallback = null;

    if (mediaProperties.media?.type === "video")
        Media = (
            <video
                className={
                    FullSizeMedia({
                        isFullsizeLoaded,
                        isVideo: true
                    })
                }
                autoPlay={true}
                muted={true}
                loop={true}
                playsInline={true}
                onPlay={() => setIsFullsizeLoaded(true)}
                onCanPlay={() => setIsFullsizeLoaded(true)}
            >
                <source type={`video/${mediaProperties.media.format}`} src={mediaProperties.media.image_scheme}></source>
            </video>
        );
    else if (!!embed)
        Media = isWidthCalculated && (
            <MediaIframe
                className={
                    FullSizeMedia({
                        isFullsizeLoaded,
                    })
                }
                src={embed}
                width={`${ref.current.offsetWidth}px`}
                height={`${ref.current.offsetHeight}px`}
                sandbox="allow-scripts"
                scrolling="no"
                onLoad={handleFullsizeLoad}
            />
        );
    else {
        Media = isWidthCalculated && (
            <picture>
                <source srcSet={sourceUrl.replace(`.${urlExtension}`, ".webp")} type="image/webp" />
                <source srcSet={sourceUrl.replace(".webp", `.${urlExtension}`)} type={`image/${urlExtension}`} />
                <img
                    className={FullSizeMedia({
                        isFullsizeLoaded, centerX
                    })}
                    src={sourceUrlFallback}
                    alt={alt}
                    title={title}
                    width={`${ref.current.offsetWidth}px`}
                    height={`${ref.current.offsetHeight}px`}
                    onLoad={handleFullsizeLoad}
                    fetchpriority={fetchpriority}
                />
            </picture>
        );

        fallback = disablePreload ? (
            <picture>
                <source media="(min-width: 996px)" srcSet={getMediaUrl(src, mediaProperties, 948).replace(`.${urlExtension}`, ".webp")} type="image/webp" />
                <source media="(min-width: 996px)" srcSet={getMediaUrl(src, mediaProperties, 948).replace(".webp", `.${urlExtension}`)} type={`image/${urlExtension}`} />
                <source media="(min-width: 768px)" srcSet={getMediaUrl(src, mediaProperties, 750).replace(`.${urlExtension}`, ".webp")} type="image/webp" />
                <source media="(min-width: 768px)" srcSet={getMediaUrl(src, mediaProperties, 750).replace(".webp", `.${urlExtension}`)} type={`image/${urlExtension}`} />
                <source srcSet={getMediaUrl(src, mediaProperties, 360).replace(`.${urlExtension}`, ".webp")} type="image/webp" />
                <source srcSet={getMediaUrl(src, mediaProperties, 360).replace(".webp", `.${urlExtension}`)} type={`image/${urlExtension}`} />
                <img src={getMediaUrl(src, mediaProperties, 360).replace(".webp", `.${urlExtension}`)} alt={alt} title={title} />
            </picture>
        ) : null;
    }

    return <Wrap ref={ref} style={WrapperSize}>
        {(isFullsizeLoaded || disablePreload) ||
            <PlaceholderWrapper className="placeholder-wrapper" isDarkMode={true}>
                {isPlaceholder ?
                    <PlaceholderLogo isCustomPlaceholder={isPlaceholder} src={APP_SETTINGS.placeholder} isFullsizeLoaded={isFullsizeLoaded} alt="logo" />
                    :
                    <PlaceholderLogo className="placeholder-gray" isCustomPlaceholder={isPlaceholder} src={UIStore.appTheme.logoSimple} isFullsizeLoaded={isFullsizeLoaded} alt="logo" />
                }
            </PlaceholderWrapper>
        }
        {(visibilityTimeout || disablePreload) && (
            <NoSSR fallback={fallback}>
                {Media}
            </NoSSR>
        )}
    </Wrap>
});

const Wrap = styled('div', {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#f0f0f0',
    height: '100%',
    '.placeholder-gray': {
        webkitFilter: 'grayscale(100%)',
        maxWidth: 'fit-content'
    },
    variants: {
        isDarkMode: {
            true: {
                backgroundColor: "#4b4a4a"
            },
            false: {}
        }
    }
});

//Powoduje błędy kompilacji edytora, ale raczej nie wymagane - https://gitlab.com/skolimow/natemat/natemat/-/merge_requests/1781
const PlaceholderLogo = /*withThemeContext(*/styled('img', {
    position: 'absolute',
    margin: 'auto',
    inset: 0,
    zIndex: 0,
    variants: {
        isCustomPlaceholder: {
            true: {
                height: '100% !important',
                maxWidth: '100%',
                maxHeight: '100%',
                objectPosition: 'center',
                objectFit: 'cover'
            },
            false: {
                transform: 'scale(0.5)'
            }
        },
        isDarkMode: {
            true: {
                opacity: "0.8"
            },
            false: {
                opacity: "0.134"
            }
        }
    }
})
//);

const Placeholder = styled('img', {
    filter: 'blur(8px)',
    objectFit: 'cover',
    minHeight: '100%',
    transition: 'opacity 800ms',
    zIndex: 2,
    variants: {
        isFullsizeLoaded: {
            true: {
                opacity: 0
            },
            false: {
                opacity: 1
            }
        }
    }
});

//Powoduje błędy kompilacji edytora, ale raczej nie wymagane - https://gitlab.com/skolimow/natemat/natemat/-/merge_requests/1781
const PlaceholderWrapper = /*withThemeContext(*/styled('div', {
    zIndex: 2,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: "#f0f0f0",
    // variants: {
    //     "isDarkMode":{
    //         true:{
    //             backgroundColor: "#4b4a4a"
    //         },
    //         false: {}
    //     }
    // }
})
//);

const FullSizeMedia = css({
    position: 'absolute',
    inset: 0,
    height: '100%',
    minHeight: 'auto',
    transition: 'opacity 300ms',
    zIndex: 3,
    variants: {
        isFullsizeLoaded: {
            true: {
                opacity: 1
            },
            false: {
                opacity: 0
            }
        },
        centerX: {
            true: {
                left: '-50%',
                height: '100%'
            },
            false: {}
        },
        isVideo: {
            true: {
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
            },
            false: {}
        }
    }
});

export const MediaIframe = styled("iframe", {
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    cursor: 'pointer',
    background: 'black',
    border: 'none',
    overflow: 'hidden'
});

export default ProgressiveMedia