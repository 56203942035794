import React, { useState } from 'react';
import { defaultColors, styled } from "../../stitches.config";
import { bodyLarge, body } from "../commonStyles/Fonts";
import { withThemeContext } from "../components/StitchesThemeProvider";
import { useStores, pushDataLayer } from "../../helpers"

import LikeIcon from "../assets/icons/socialbutton-newlike.svg";
import LikeIconBlack from "../assets/icons/socialbutton-like-dark.svg";

import { ReactComponent as LikeIconNav } from "../assets/icons/nav/article-nav/heart.svg?react";

interface SocialButtonProps {
  url: string;
  number?: number;
  showText?: boolean;
  isDarkMode?: boolean;
  isDarkModeNotPrimary?: boolean
  showShare?: boolean;
  isNavArticle?: boolean;
}


const SocialButton: React.FC<SocialButtonProps> = ({
  number = 0,
  isDarkMode,
  isDarkModeNotPrimary,
  url,
  showShare = false,
  showText = false,
  isNavArticle = false,
  ...props
}) => {
  const type = "like";

  const { ArticleStore } = useStores();
  const dataLayerObject = {
    'event': 'clickSocialIcon',
    'articleUrl': ArticleStore.article.fb_url,
    // STORYBOOK FIX
    'articleTitle': ArticleStore.article.seo_title,
    'articleSection': ArticleStore.article.category?.name,
    'articleTag': (ArticleStore.fetchedArticle.topics && ArticleStore.fetchedArticle.topics.length > 0) ? ArticleStore.fetchedArticle.topics[0].name : '',
    'articleAuthor': ArticleStore.fetchedArticle.DATA_LAYER?.articleAuthor,
    'articleCharacterRange': ArticleStore.fetchedArticle.DATA_LAYER?.articleCharacterRange
  }

  // chowam, bo FB sie czepia o niego (#7750)
  return null;

  return (
    <StyledSocialButton
      href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(url)}
      target="_blank"
      onClick={() => pushDataLayer(dataLayerObject)}
    >
      {isNavArticle ?
        <SingleIcon>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.30016 3.41667C4.20596 3.41667 2.50016 5.123 2.50016 7.24167C2.50016 9.88113 3.71759 12.002 5.26528 13.5877C6.82303 15.1838 8.66399 16.1814 9.75272 16.553L9.76086 16.5558L9.76085 16.5558C9.78558 16.5646 9.87224 16.5833 10.0002 16.5833C10.1281 16.5833 10.2147 16.5646 10.2395 16.5558L10.2476 16.553L10.2476 16.553C11.3363 16.1814 13.1773 15.1838 14.735 13.5877C16.2827 12.002 17.5002 9.88113 17.5002 7.24167C17.5002 5.123 15.7944 3.41667 13.7002 3.41667C12.4672 3.41667 11.3607 4.01568 10.6692 4.94685C10.512 5.15854 10.2638 5.28333 10.0002 5.28333C9.73648 5.28333 9.48835 5.15854 9.33114 4.94685C8.63911 4.015 7.54068 3.41667 6.30016 3.41667ZM0.833496 7.24167C0.833496 4.21033 3.2777 1.75 6.30016 1.75C7.73161 1.75 9.03052 2.30357 10.0006 3.20615C10.9728 2.30324 12.2759 1.75 13.7002 1.75C16.7226 1.75 19.1668 4.21033 19.1668 7.24167C19.1668 10.4355 17.6843 12.9522 15.9278 14.7518C14.1829 16.5396 12.1177 17.675 10.7896 18.1291C10.5322 18.2191 10.2458 18.25 10.0002 18.25C9.75455 18.25 9.46815 18.2191 9.21074 18.1291C7.88267 17.675 5.81742 16.5396 4.07254 14.7518C2.31607 12.9522 0.833496 10.4355 0.833496 7.24167Z" />
          </svg>
        </SingleIcon>
        :
        <>
          <StyledSocialButtonIcon
            type={type}
            variant={isDarkMode ? isDarkModeNotPrimary ? "darkNotPrimary" : "dark" : undefined}
            className="social-btn-icon"
          >
            {number > 0 && <StyledSocialButtonNumber className={bodyLarge()}>
              {number}
            </StyledSocialButtonNumber>}
          </StyledSocialButtonIcon>
          {showText === true && (
            <StyledSocialButtonText className={bodyLarge()}>
              <span className={"text"}>Lubię to</span>
              {number > 0 && number}
            </StyledSocialButtonText>
          )}
        </>
      }
    </StyledSocialButton>
  );
};

const SingleIcon = styled('div', {
  width: "20px",
  height: "20px",
  "svg": {
    "path": {
      fill: "$primary"
    },
  }
})


const StyledSocialButton = styled("a", {
  display: "flex",
  alignItems: "center",
  position: "relative",
  "& p": {
    margin: 0,
    color: "$grayscale100",
  },
  "& .text": {
    display: "none",
    "@bp4": {
      display: "block",
    },
  },
});

const StyledSocialButtonText = styled("p", {
  display: "none",
  "@bp4": {
    display: "flex",
  },
});

const StyledSocialButtonNumber = styled('span', {
  color: "$grayscale0",
  paddingLeft: "24px",
  '@bp4': {
    display: "none",
  },
})


const StyledSocialButtonIcon = styled('span', {
  backgroundColor: "$grayscale100",
  width: "max-content",
  padding: "4px 12px 4px 10px",
  borderRadius: "50px",
  borderWidth: 0,
  backgroundRepeat: "no-repeat",
  cursor: "pointer",
  minWidth: "32px",
  minHeight: "32px",
  transition: "0.2s",
  "&:hover": {
    backgroundColor: "$primary",
  },
  '@bp4': {
    padding: "0",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginRight: "10px",
  },
  variants: {
    type: {
      like: {
        backgroundImage: `url("${LikeIcon}")`,
        backgroundSize: "18px",
        backgroundPosition: "center",
      },
    },
    variant: {
      dark: {
        backgroundColor: "$primary",
      },
      darkNotPrimary: {
        backgroundColor: "$grayscale25",
        backgroundImage: `url("${LikeIconBlack}")`,
      }
    },
  },
});

export default withThemeContext(SocialButton);
